import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  pageCreateNft: {
    width: "100%",
    //maxWidth: "1100px",
    //margin: "0 auto",

    display: "flex",
    flexDirection: "column",

    "& form": {
      margin: "20px auto 80px auto",
      width: "730px",
      background: "#FFF",
      borderRadius: "8px",

      display: "flex",
      flexDirection: "column",

      "& fieldset": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",

        width: "400px",
        minWidth: "240px",
        marginTop: "64px",
        marginLeft: "2rem",
        minInlineSize: "auto",
        border: "0",
      },
    },
  },

  formHeader: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",

    "& h1": {
      fontSize: "36px",
      marginTop: "20px"
    },

    "& a": {
      marginLeft: "auto",
      marginRight: "1.5rem",
    },
  },

  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "400px",
  },

  svgZone: {
    width: "250px",
    marginTop: "10px",
    marginBottom: "20px",

    "& fill::hover": {
      fill : "none",
    }
  },

  mintZone: {
    marginTop: "20px",
    marginBottom: "50px"
  },

  indexes: {
    minHeight: "50px",
    marginTop: "20px"
  }
});

export { useStyles };
