import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { useMediaQuery } from "@material-ui/core";

import getWeb3 from "../../utils/getWeb3";
import { api } from "../../services/api";

import ArtMarketplace from "../../contracts/ArtMarketplace.json";
import { useLocation } from "react-router-dom";

import {
  setNft,
  setAccount,
  setTokenContract,
  setMarketContract,
  setOffspring,
} from "../../redux/actions/nftActions";
import Card from "../../components/Card";

import { useStyles } from "./styles.js";

import veterans from "../../assets/arts/Image1.jpg";
import lionKing from "../../assets/arts/Image2.jpg";
import dreaming from "../../assets/arts/Image3.jpg";
import modeling3d from "../../assets/arts/Image5.jpg";
import woman from "../../assets/arts/Image5.jpg";
import stones from "../../assets/arts/Image6.jpg";
import wale from "../../assets/arts/Image7.jpg";
import comic from "../../assets/arts/Image8.jpg";
//import scribbles from "../../assets/Logo.svg";
import offspring from "../../assets/scribbleOffspring.svg";

import OffspringJSON from "../../contracts/ScribblesOffspring.json"


const Offset = () => {
  const classes = useStyles();
  const nft = useSelector((state) => state.allNft.offspring);
  const offspringAddress = "0x1E03ca2D54682ecdE845e5385f5AB8E0E62343C9";
  const marketplaceAddress = "0x8feA785c3f693242A739422300300Ce82e4989ff";
  const [lastNFTPosition, setLastNFTPosition] = useState();
  const dispatch = useDispatch();
  let txt = "";
  let isParent = false;
  const sizeSM = useMediaQuery('(min-width:300px)');
  const sizeMD = useMediaQuery('(min-width:600px)');
  const sizeLG = useMediaQuery('(min-width:900px)');

  useEffect(() => {
    let itemsList = [];
    const init = async () => {
      try {
        console.log("useEffect triggered");
        const web3 = await getWeb3();
        console.log("web3 triggered");
        const accounts = await web3.eth.getAccounts();
        

        if (typeof accounts === undefined) {
          alert("Please login with Metamask!");
          console.log("login to metamask");
        }

        const networkId = await web3.eth.net.getId();
        
        try {
          console.log("inner try triggered");
          const artTokenContract = new web3.eth.Contract(
            OffspringJSON.abi,
            offspringAddress
          );
          // console.log("Contract: ", artTokenContract);
          const marketplaceContract = new web3.eth.Contract(
            ArtMarketplace.abi,
            marketplaceAddress
          );
          const totalSupply = await artTokenContract.methods
            .totalSupply()
            .call();
          const totalItemsForSale = await marketplaceContract.methods
            .totalItemsForSale()
            .call();

          var position = totalSupply;
          var lastPosition = position - 8;
          lastPosition = lastPosition < 1 ? 1 : lastPosition;
          for (var tokenId = position; tokenId >= lastPosition; tokenId--) {
            // let item = await artTokenContract.methods.Items(tokenId).call();
            let owner = await artTokenContract.methods.ownerOf(tokenId).call();

            // const response = await api
            //   .get(`/tokens/${tokenId}`)
            //   .catch((err) => {
            //     console.log("Err: ", err);
            //   });
            // console.log("response: ", response);
            console.log(tokenId);
            itemsList.push({
              name: tokenId,
              description: "Offspring",
              image: null,
              tokenId: tokenId.toString(),
              creator: null,
              owner: owner,
              uri: null,
              isForSale: false,
              saleId: null,
              price: 0,
              isConcluded: null,
              isParent: false
            });
          }
          setLastNFTPosition(lastPosition)
          if (totalItemsForSale > 0) {
            for (var saleId = 0; saleId < totalItemsForSale; saleId++) {
              let item = await marketplaceContract.methods
                .itemsForSale(saleId)
                .call();
              let active = await marketplaceContract.methods
                .activeScribblesItems(item.tokenId)
                .call();

              let itemListIndex = itemsList.findIndex(
                (i) => i.tokenId === item.tokenId
              );

              itemsList[itemListIndex] = {
                ...itemsList[itemListIndex],
                isForSale: active,
                saleId: item.id,
                price: item.price,
                isConcluded: item.isConcluded,
              };
            }
          }

          dispatch(setAccount(accounts[0]));
          dispatch(setMarketContract(marketplaceContract));
          dispatch(setTokenContract(artTokenContract));
          dispatch(setOffspring(itemsList));
        } catch (error) {
          console.error("Error", error);
          alert(
            "Contracts not deployed to the current network " +
              networkId.toString()
          );
        }
      } catch (error) {
        alert(
          `Failed to load web3, accounts, or contract. Check console for details.` +
            error
        );
        console.error(error);
      }
    };
    init();
  }, [txt]);

  console.log("Nft :", nft);
  txt = window.location.pathname.split('/').pop();
  // setURL(txt);

  const nftItem = useSelector((state) => state.allNft.offspring);

  return (
    <div className={classes.homepage}>
      <section className={classes.banner}>
        <Grid container spacing={0} xs={12} className={classes.gridBanner}>
          <Grid item xs={3}>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <img src={dreaming} alt="dreaming" className={classes.images} />
              </Grid>
              <Grid item xs={4}>
                <img src={veterans} alt="veterans" className={classes.images} />
              </Grid>
              <Grid item xs={7}>
                <img src={modeling3d} alt="modeling3d" className={classes.images} />
              </Grid>
              <Grid item xs={5}>
                <img src={lionKing} alt="lionKing" className={classes.images} />
              </Grid>
            </Grid>
          </Grid>
          <Grid  item xs={6} className={classes.main}>
            {sizeMD ? <img src={offspring} alt="galerie" /> : null}
            {
              sizeMD ?
                <Typography>
                  Scribbles & Scribbles Offspring are an NFT Collection that enables Original Scribbles NFTs to seed unique original generative pieces of digital Art in the form of NFTs.
                </Typography>
                :
                null
            }
            <Link to="/create-nft">
              <Button variant="contained" color="primary" disableElevation>
                Create {sizeMD ? "Your" : null} Offspring
              </Button>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <img src={stones} alt="dreaming" className={classes.images} />
              </Grid>
              <Grid item xs={4}>
                <img src={woman} alt="veterans" className={classes.images} />
              </Grid>
              <Grid item xs={7}>
                <img src={wale} alt="modeling3d" className={classes.images} />
              </Grid>
              <Grid item xs={5}>
                <img src={comic} alt="lionKing" className={classes.images} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section className={classes.allNfts}>
        <Typography className={classes.title}>Latest Offspring :</Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {nftItem.map((nft) => (
            <Grid item key={nft.tokenId}>
              <Card {...nft} />
            </Grid>
          ))}
        </Grid>
      </section>
    </div>
  );
};

export default Offset;
