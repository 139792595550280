import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Switch from '@material-ui/core/Switch';
import TextField from "@material-ui/core/TextField";
import { Random } from "random-js";
import getWeb3 from "../../utils/getWeb3";
import { api } from "../../services/api";
import { useMediaQuery } from "@material-ui/core";

import ArtMarketplace from "../../contracts/ArtMarketplace.json";
import ArtToken from "../../contracts/ArtToken.json";
import ScribblesABI from "../../contracts/scribbles.json";
import svgData from "../../data.json"

import {
  setNft,
  setAccount,
  setTokenContract,
  setMarketContract,
} from "../../redux/actions/nftActions";
import Card from "../../components/Card";

import { useStyles } from "./styles.js";

import Image8 from "../../assets/arts/Image2.jpg";
import Image7 from "../../assets/arts/Image3.jpg";
import Image6 from "../../assets/arts/Image4.jpg";
import Image5 from "../../assets/arts/Image6.jpg";
import Image4 from "../../assets/arts/Image7.jpg";
import Image3 from "../../assets/arts/Image8.jpg";
import Image2 from "../../assets/arts/Image5.jpg";
import Image1 from "../../assets/arts/Image1.jpg";
import scribbles from "../../assets/Logo.svg";


const Home = () => {
  const classes = useStyles();
  const nft = useSelector((state) => state.allNft.nft);
  const parentAddress = "0xDB18774dCa16F1c5C2F7Af640EBA3edb19343D7d";
  const marketplaceAddress = "0x8feA785c3f693242A739422300300Ce82e4989ff";
  const dispatch = useDispatch();
  const random = new Random();
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [relevantIndexes, setRelevantIndexes] = useState([]);
  const [ownedOnly, setOwnedOnly] = useState(false);
  const [listingsOnly, setListingsOnly] = useState(false);

  const sizeSM = useMediaQuery('(min-width:300px)');
  const sizeMD = useMediaQuery('(min-width:600px)');
  const sizeLG = useMediaQuery('(min-width:900px)');

  useEffect(() => {
    let itemsList = [];
    const init = async () => {
      setLoadingMore(true);
      try {
        const web3 = await getWeb3();
        const accounts = await web3.eth.getAccounts();

        if (typeof accounts === undefined) {
          alert("Please login with Metamask!");
          console.log("login to metamask");
        }

        const networkId = await web3.eth.net.getId();
        try {
          const artTokenContract = new web3.eth.Contract(
            ScribblesABI,
            parentAddress
          );
          const marketplaceContract = new web3.eth.Contract(
            ArtMarketplace.abi,
            marketplaceAddress
          );
          const totalSupply = await artTokenContract.methods
            .totalSupply()
            .call();
          const totalItemsForSale = await marketplaceContract.methods
            .totalItemsForSale()
            .call();
          // var position = totalSupply - 1;
          // var lastPosition = position - 7;

          // lastPosition = lastPosition < 0 ? 0 : lastPosition;
          for (var tokenId = 0; tokenId <= totalSupply - 1; tokenId++) {
            // let item = await artTokenContract.methods.Items(tokenId).call();
            // let owner = await artTokenContract.methods.ownerOf(tokenId).call();
            // let tokenURI = await artTokenContract.methods.tokenURI(tokenId).call();

            // const response = await api
            //   .get(`/tokens/${tokenId}`)
            //   .catch((err) => {
            //     console.log("Err: ", err);
            //   });
            // console.log("response: ", response);
            itemsList.push({
              name: tokenId,
              description: "Scribbles",
              image: svgData[tokenId],
              tokenId: tokenId.toString(),
              creator: null,
              owner: null,
              uri: null,
              isForSale: null,
              saleId: null,
              price: 0,
              isSold: null,
              isParent: true
            });
          }
          dispatch(setAccount(accounts[0]));
          dispatch(setTokenContract(artTokenContract));
          dispatch(setMarketContract(marketplaceContract));
          dispatch(setNft(itemsList));
          console.log("state saved successfully");
        } catch (error) {
          console.error("Error", error);
          alert(
            "Contracts not deployed to the current network " +
            networkId.toString()
          );
        }
      } catch (error) {
        alert(
          `Failed to load web3, accounts, or contract. Check console for details.` +
          error
        );
        console.error(error);
      }
    };
    init();
    setLoadingMore(false);
  }, [dispatch]);

  var nftItem = useSelector((state) => state.allNft.nft);
  const artTokenContract = useSelector((state) => state.allNft.artTokenContract);
  const marketplaceContract = useSelector((state) => state.allNft.marketplaceContract);
  const account = useSelector((state) => state.allNft.account);

  const handleOwnedOnly = async () => {
    setLoadingMore(true);
    if (listingsOnly) {
      setRelevantIndexes([]);
      setListingsOnly(false);
    }
    if (!ownedOnly) {
      setOwnedOnly(true);
      var indexes = [];
      var count = await artTokenContract.methods.balanceOf(account).call();
      console.log(count);
      if (count > 0) {
        for (let i = 0; i < count; i++) {
          // get owned NFT index
          let NFTIndex = await artTokenContract.methods.tokenOfOwnerByIndex(account, i).call();
          indexes.push(String(NFTIndex));
        }
        setRelevantIndexes(indexes);
      }
    }
    setLoadingMore(false);
  }

  const handleListings = async () => {
    setOwnedOnly(false);
    setListingsOnly(true);
    try {
      console.log("test");
      const totalItemsForSale = await marketplaceContract.methods
        .totalItemsForSale()
        .call();
      console.log("totalItemsForSale" + totalItemsForSale);
      if (totalItemsForSale > 0) {
        let indexes = [];
        for (var saleId = 0; saleId < totalItemsForSale; saleId++) {
          let item = await marketplaceContract.methods
            .itemsForSale(saleId)
            .call();

          if (item.isParent) {
            let active = await marketplaceContract.methods
              .activeParentItems(item.tokenId)
              .call();

            if (active) {
              console.log(item.tokenId);
              let itemListIndex = nftItem.findIndex(
                (i) => i.tokenId === item.tokenId
              );
              indexes.push(String(item.tokenId));
              nftItem[itemListIndex] = {
                ...nftItem[itemListIndex],
                isForSale: active,
                saleId: item.id,
                price: item.price,
                isSold: item.isSold,
              };
            }
          }
        }
        setRelevantIndexes(indexes);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleAll() {
    setRelevantIndexes([]);
    setOwnedOnly(false);
    setListingsOnly(false);
  }

  return (
    <div className={classes.homepage}>
      <section className={classes.banner}>
        <Grid container spacing={0} xs={12} className={classes.gridBanner}>
          <Grid item xs={3}>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <img src={random.integer(0, 1) == 1 ? Image3 : Image6} alt="" className={classes.images} />
              </Grid>
              <Grid item xs={4}>
                <img src={random.integer(0, 1) == 1 ? Image4 : Image8} alt="" className={classes.images} />
              </Grid>
              <Grid item xs={7}>
                <img src={random.integer(0, 1) == 1 ? Image3 : Image6} alt="" className={classes.images} />
              </Grid>
              <Grid item xs={5}>
                <img src={random.integer(0, 1) == 1 ? Image2 : Image1} alt="" className={classes.images} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.main}>
            {sizeMD ? <img src={scribbles} alt="galerie" /> : null}
            {
              sizeMD ?
                <Typography>
                  Scribbles & Scribbles Offspring are an NFT Collection that enables Original Scribbles NFTs to seed unique original generative pieces of digital Art in the form of NFTs.
                </Typography>
                :
                null
            }
            <Link to="/create-nft">
              <Button variant="contained" color="primary" disableElevation>
                Create {sizeMD ? "Your" : null} Offspring
              </Button>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <img src={random.integer(0, 1) == 1 ? Image5 : Image1} alt="" className={classes.images} />
              </Grid>
              <Grid item xs={4}>
                <img src={random.integer(0, 1) == 1 ? Image7 : Image6} alt="" className={classes.images} />
              </Grid>
              <Grid item xs={7}>
                <img src={random.integer(0, 1) == 1 ? Image8 : Image2} alt="" className={classes.images} />
              </Grid>
              <Grid item xs={5}>
                <img src={random.integer(0, 1) == 1 ? Image2 : Image1} alt="" className={classes.images} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section className={classes.allNfts}>
        <div style={{ paddingBottom: 30 }}>
          <Grid container xs={12} justifyContent={ sizeMD ? "flex-end" : "center"} direction="row" spacing={2} alignItems="center" >
            <Grid item xs={sizeMD ? 2 : 12}>
              <Box display="flex" justifyContent={ sizeMD ? "flex-end" : "center"} alignItems="center">
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button onClick={() => handleAll()}>All</Button>
                  <Button onClick={() => handleOwnedOnly()}>Owned</Button>
                  <Button onClick={() => handleListings()}>Listed</Button>
                </ButtonGroup>
              </Box>
            </Grid>
            {/* {sizeMD ? */}
              <Grid item xs={sizeMD ? 2 : 5}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  label="Search"
                  name="description"
                  variant="outlined"
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  fullWidth
                />
              </Grid> 
              {/* null
            } */}

          </Grid>
        </div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {
            nftItem.filter((nft) => {
              if (relevantIndexes.length == 0) {
                if (!listingsOnly && !ownedOnly) {
                  if (searchTerm == "") {
                    return nft;
                  } else if (nft.tokenId.includes(searchTerm)) {
                    return nft;
                  }
                }
              } else if (relevantIndexes.indexOf(nft.tokenId) !== -1) {
                if (searchTerm == "") {
                  return nft;
                } else if (nft.tokenId.includes(searchTerm)) {
                  return nft;
                }
              }
            }).map((nft) => (
              <Grid item key={nft.tokenId}>
                <Card {...nft} listingsOnly={listingsOnly} />
              </Grid>
            ))

          }
        </Grid>
      </section>
    </div>
  );
};

export default Home;
