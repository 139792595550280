import { ActionTypes } from "../constants/action-types";

export const setNft = (nft) => {
  return {
    type: ActionTypes.SET_NFT,
    payload: nft,
  };
};

export const selectedNft = (nft) => {
  return {
    type: ActionTypes.SELECTED_NFT,
    payload: nft,
  };
};

export const removeSelectedNft = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_NFT,
  };
};

export const setAccount = (account) => {
  return {
    type: ActionTypes.SET_ACCOUNT,
    payload: account,
  };
};

export const setTokenContract = (tokenContract) => {
  return {
    type: ActionTypes.SET_TOKEN_CONTRACT,
    payload: tokenContract,
  };
};

export const setOffspringContract = (offspringContract) => {
  return {
    type: ActionTypes.SET_OFFSPRING_CONTRACT,
    payload: offspringContract,
  };
};


export const setOffspring = (offspringNFT) => {
  return {
    type: ActionTypes.SET_OFFSPRING,
    payload: offspringNFT,
  };
};

export const setMarketContract = (marketContract) => {
  return {
    type: ActionTypes.SET_MARKET_CONTRACT,
    payload: marketContract,
  };
};