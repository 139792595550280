import React, { useRef, useState, useEffect, useCallback } from 'react';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Alert from '@material-ui/material/Alert';
// import AlertTitle from '@material-ui/material/AlertTitle';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FormHelperText } from '@material-ui/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 520,
    bgcolor: '#1d2760',
    border: '3px solid #1d2760',
    color: '#fff',
    borderRadius: 8,
    boxShadow: 24,
    p: 4
};

const svgStyle = {
    position: 'relative',
    left: '13%',
    width: 200,
    height: 280,
    mt: 3,
};

const LoyaltyModal = ({ showModal, setShowModal, ownedNFTs, offspringContract, account }) => {
    const handleClose = () => setShowModal(false);
    const [parentNFT, setParentNFT] = useState();
    const [loyalty, setLoyalty] = useState(0);
    const [TXPending, setTXPending] = useState(false);

    useEffect(async () => {
        if (parentNFT) {
            let loyaltyAvailable = await offspringContract.methods.getParentLoyalty(parentNFT.index).call();
            loyaltyAvailable = loyaltyAvailable / 1000000000000000000;
            setLoyalty(loyaltyAvailable);
        }
    }, [parentNFT])

    const withdrawLoyalty = async () => {
        setTXPending(true);
        await offspringContract.methods.loyaltyWithdraw(parentNFT.index).send(
            { from: account }
        ).then(tx => {
            window.alert(tx)
            setLoyalty(0);
            setTXPending(false);
        }).catch(err => {
            window.alert(`withdrawal errored : ${err}`);
            setTXPending(false);
        });
    };

    return (
        <>
            {showModal ? (
                <Modal
                    open={showModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h5" component="h2"  >
                            Owned Scribbles
                        </Typography>
                        <Box component="div" sx={{ position: 'relative', mt: 2 }}>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ position: 'relative', mt: 2 }}>
                                {
                                    ownedNFTs ? ownedNFTs.map(NFT => {
                                        return (
                                            <Button onClick={() => setParentNFT(NFT)}>{NFT.index}</Button>
                                        )
                                    }) : <Button>Nothing found</Button>
                                }

                            </ButtonGroup>
                        </Box>
                        <Box sx={svgStyle}>
                            {
                                parentNFT ?
                                    <div dangerouslySetInnerHTML={{ __html: parentNFT.svg }} />
                                    : <div></div>
                            }
                        </Box>
                        <Box component="div" sx={{ display: 'inline' }}>
                            <Typography id="modal-modal-description" sx={{ mt: 20 }}>
                                Available Loyalty
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 20, mb: 2 }}>
                                {loyalty} ONE
                            </Typography>
                        </Box>
                        <Box component="div" sx={{ position: 'relative', mt: 1, ml: 15 }}>
                            {
                                loyalty > 0 && !TXPending ?
                                    <Button onClick={withdrawLoyalty} variant="contained" aria-label="outlined primary button group" >Withdraw</Button> :
                                    null
                            }
                        </Box>
                        {/* {TXPending ? 
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            This is an info alert — <strong>check it out!</strong>
                        </Alert> :
                        null} */}
                    </Box>
                </Modal>
            ) : null}
        </>
    );
};

export default LoyaltyModal;