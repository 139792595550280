export const ActionTypes = {
  SET_NFT: "SET_NFT",
  SET_OFFSPRING: "SET_OFFSPRING",
  SELECTED_NFT: "SELECTED_NFT",
  REMOVE_SELECTED_NFT: "REMOVE_SELECTED_NFT",
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_TOKEN_CONTRACT: "SET_TOKEN_CONTRACT",
  SET_OFFSPRING_CONTRACT: "SET_OFFSPRING_CONTRACT",
  SET_MARKET_CONTRACT: "SET_MARKET_CONTRACT"
};
