import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header/index";
import Home from "./pages/Home/index";
import CreateNFT from "./pages/CreateNFT/index";
import Offset from "./pages/Offset/index";
import Item from "./pages/Item/index";
import { Paper } from "@material-ui/core";
import ScribblesABI from './pages/CreateNFT/abi/scribbles.json'
import ArtMarketplace from "./contracts/ArtMarketplace.json";
import Offspring from './pages/CreateNFT/abi/ScribblesOffspring.json'
import { useDispatch, useSelector } from "react-redux";

import "./App.css";
import LoyaltyModal from "./components/Modal/Modal";
import Web3 from "web3";
import { setMarketContract, setOffspringContract, setTokenContract } from "./redux/actions/nftActions";

function App() {
  const [showLoyaltyModal, setShowLoyaltyModal] = useState(false);

  const [account, setAccount] = useState();
  const parentAddress = "0xDB18774dCa16F1c5C2F7Af640EBA3edb19343D7d";
  const offspringAddress = "0x1E03ca2D54682ecdE845e5385f5AB8E0E62343C9";
  const marketplaceAddress = "0x8feA785c3f693242A739422300300Ce82e4989ff";
  const dispatch = useDispatch();

  const [scribblesContract, setScribblesContract] = useState();
  const [offspringContract, setOffContract] = useState();
  const [NFTs, setNFTs] = useState();

  useEffect(() => {
    loadWeb3();
    window.ethereum.on('accountsChanged', () => {
      window.location.reload();
    });

    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    });
  }, [])

  const loadWeb3 = async () => {
    window.web3 = new Web3(window.ethereum);
    // await window.ethereum.request({ method: 'eth_requestAccounts' });
    const accounts = await window.web3.eth.getAccounts();
    setAccount(accounts[0]);
    getOwnedParents();
  };

  const getOwnedParents = async () => {
    window.web3 = new Web3(window.web3.currentProvider)
    const accounts = await window.web3.eth.getAccounts()
    const web3 = window.web3;
    console.log("account: " + accounts[0]);
    var chainID = await web3.eth.net.getId();
    if (chainID == 1666600000) {

      const contract = new web3.eth.Contract(ScribblesABI, parentAddress);
      setScribblesContract(contract);
      const offContract = new web3.eth.Contract(Offspring.abi, offspringAddress);
      setOffContract(offContract);
      const marketplaceContract = new web3.eth.Contract(ArtMarketplace.abi, marketplaceAddress);
      console.log("dispatch");
      dispatch(setTokenContract(contract));
      dispatch(setOffspringContract(offContract));
      dispatch(setMarketContract(marketplaceContract));

      var data = []
      var count = await contract.methods.balanceOf(accounts[0]).call();
      console.log("count is : " + count);
      if (count > 0) {
        setNFTs([]);
        for (let i = 0; i < count; i++) {
          let NFTIndex = await contract.methods.tokenOfOwnerByIndex(accounts[0], i).call();
          let NFTSVG = await contract.methods.tokenURI(NFTIndex).call();
          console.log(NFTIndex + ":" + NFTSVG);
          data.push({ index: NFTIndex, svg: NFTSVG });
        }
        setNFTs(data);
      }
    } else {
      window.alert('you are not on harmony mainnet, please choose the right network');
    }
  }

  const toggleModal = () => {
    setShowLoyaltyModal(prev => !prev);
  };
  return (
    <div className="App">
      <Router>
        <Header onModalToggle={toggleModal} account={account} />
        {
          showLoyaltyModal ?
            <LoyaltyModal
              showModal={showLoyaltyModal}
              setShowModal={setShowLoyaltyModal}
              ownedNFTs={NFTs}
              offspringContract={offspringContract} account={account} />
            :
            null
        }
        
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/offspring" exact component={Offset} />
          <Route path="/create-nft" component={CreateNFT} />
          <Route path="/nft/:type/:nftId" component={Item} />
          <Route>404 Not Found!</Route>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
