import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card as MuiCard } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import getWeb3 from "../../utils/getWeb3";
import ScribblesABI from "../../contracts/scribbles.json";
import OffspringJSON from "../../contracts/ScribblesOffspring.json";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Web3 from "web3";
import svgData from "../../data.json"

import ArtMarketplace from "../../contracts/ArtMarketplace.json";

import { selectedNft, removeSelectedNft } from "../../redux/actions/nftActions";

import { useStyles } from "./styles.js";

const Item = () => {
  const classes = useStyles();
  var web3 = new Web3(window.ethereum);

  const { type, nftId } = useParams();
  const [salePrice, setSalePrice] = useState();
  const [txPending, setTxPending] = useState(false);
  const [txFinished, setTxFinished] = useState(false);
  var marketplaceContract = useSelector(
    (state) => state.allNft.marketplaceContract
  );
  var marketAddress = useSelector(
    (state) => state.allNft.marketplaceAddress
  );
  var artTokenContract = useSelector(
    (state) => state.allNft.artTokenContract
  );
  var offspringContract = useSelector(
    (state) => state.allNft.offspringContract
  );
  console.log(artTokenContract);

  if (artTokenContract == null) {
    artTokenContract = new web3.eth.Contract(
      ScribblesABI,
      "0xDB18774dCa16F1c5C2F7Af640EBA3edb19343D7d"
    );
  }

  if (offspringContract == null) {
    offspringContract = new web3.eth.Contract(
      OffspringJSON.abi,
      "0x1E03ca2D54682ecdE845e5385f5AB8E0E62343C9"
    );
  }

  if (marketplaceContract == null) {
    marketplaceContract = new web3.eth.Contract(
      ArtMarketplace.abi,
      "0x8feA785c3f693242A739422300300Ce82e4989ff"
    );
  }

  const account = useSelector((state) => state.allNft.account);
  let nft = useSelector((state) => state.nft);
  var allNFT = useSelector((state) => state.allNft);
  // case of nft being passed into item page
  let nftItem;
  if (type === "offspring") {
    nftItem = allNFT.offspring.filter((offspring) => offspring.tokenId === nftId);
  } else {
    nftItem = allNFT.nft.filter((nft) => nft.tokenId === nftId);
  }

  console.log(nft);
  console.log("nftId : " + nftId);

  var image, price, saleId, isForSale;
  console.log(nftItem);
  if (nftItem.length != 0) {
    image = nftItem[0].image;
    price = nftItem[0].price;
    saleId = nftItem[0].saleId;
    isForSale = nftItem[0].isForSale;
  }

  if (image == null && type === "parent") {
    image = svgData[nftId];
  }
  // set price to 0 if not for sale
  price = !isForSale ? 0 : price;

  console.log("salePrice : " + salePrice);
  console.log("isForSale : " + isForSale);
  console.log("saleId : " + saleId);

  const dispatch = useDispatch();

  const sizeSM = useMediaQuery('(min-width:300px)');
  const sizeMD = useMediaQuery('(min-width:600px)');
  const sizeLG = useMediaQuery('(min-width:900px)');

  const [nftOwner, setNFTOwner] = useState();
  var txt = null;

  useEffect(() => {
    if (nftId && nftId !== "" && nftItem) dispatch(selectedNft(nftItem[0]));
    // retrieve owner data
    const initOwner = async () => {
      if (type === "parent") {
        try {
          txt = await artTokenContract.methods.ownerOf(nftId).call();
        } catch (error) {
          console.log(error);
        }

      } else {
        try {
          txt = await offspringContract.methods.ownerOf(nftId).call();
        } catch (error) {
          console.log(error);
        }
      }
      setNFTOwner(txt);
    }
    // retrieve listing data, isForSale and price
    const initListingData = async () => {
      console.log("initTriggered");
      let totalItemsForSale;
      try {
        totalItemsForSale = await marketplaceContract.methods
          .totalItemsForSale()
          .call();
      } catch (error) {
        console.log(error);
      }
      if (totalItemsForSale > 0) {
        //loop starting from the latest listing
        for (var saleId = totalItemsForSale - 1; saleId >= 0; saleId--) {
          let item;
          try {
            item = await marketplaceContract.methods
              .itemsForSale(saleId)
              .call();
          } catch (error) {
            console.log(error);
          }
          if (type === "offspring" ?
            !item.isParent && nftId == item.tokenId :
            item.isParent && nftId == item.tokenId) {

            // if concluded, not for sale vice versa
            isForSale = item.isConcluded;
            // set price
            price = item.price;
            // break loop
            break;
          }
        }
      }
    }
    initOwner();
    if (isForSale == null) {
      initListingData();
    }
    return () => {
      dispatch(removeSelectedNft());
    };

  }, [nftId]);

  async function putForSale(id, price) {
    setTxPending(true);
    try {

      if (type === "parent") {
        await artTokenContract.methods.approve(marketAddress, id).send({ from: account })
      } else if (type === "offspring") {
        console.log("offspring triggered");
        await offspringContract.methods.approve(marketAddress, id).send({ from: account });
      }

      const receipt = await marketplaceContract.methods
        .putItemForSale(id, price, type === "parent")
        .send({ from: account });
      console.log(receipt);
      setTxFinished(true);
    } catch (error) {
      console.error("Error, putting for sale: ", error);
      alert("Error while puting for sale!");
    }
    setTxPending(false);
  }

  async function cancelListing(id) {
    setTxPending(true);
    try {
      const receipt = await marketplaceContract.methods
        .cancelListing(id)
        .send({ from: account });
      console.log(receipt);
      setTxFinished(true);
      // const id = receipt.events.saleCancelled.id; 
    } catch (error) {
      console.error("Error, cancelling listing: ", error);
      alert("Error while cancelling listing!");
    }
    setTxPending(false);

  }

  async function buy(saleId, price) {
    setTxPending(true);
    try {
      const receipt = await marketplaceContract.methods
        .buyItem(saleId)
        .send({ value: price, from: account });
      console.log(receipt);
      const id = receipt.events.itemSold.id; ///saleId
    } catch (error) {
      console.error("Error, buying: ", error);
      alert("Error while buying!");
    }
    setTxPending(false);
  }

  return (
    <Grid container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      xs={12}
      direction="column"
    >
      <Grid item xs={5}>
        <Grid container justifyContent="center">
          <Box sx={{ marginTop: "40px", marginBottom: "20px", justifyContent: "center", textAlign: "center" }}>
            <h1>{nftId}</h1>
            <TextField
              label="owner"
              name="owner"
              variant="outlined"
              key={`${Math.floor((Math.random() * 1000))}-min`}
              disabled
              fullWidth
              margin="dense"
              defaultValue={nftOwner ? nftOwner.slice(0, 7) + "..." + nftOwner.slice(-4) : null}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <div classname={classes.svgFrame}>
          <MuiCard className={classes.svgZone}>
            <div dangerouslySetInnerHTML={{ __html: image }} />
          </MuiCard>
        </div>
      </Grid>
      <Grid item xs={5}>
        <Box sx={{ marginTop: "20px" }}>
          <Grid container justifyContent="center" alignItems="center" direction="row" spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="price"
                name="price"
                variant="outlined"
                margin="dense"
                defaultValue={Web3.utils.fromWei(String(price), "ether")}
                onChange={e => setSalePrice(
                  Web3.utils.toWei(e.target.value ? e.target.value : Web3.utils.toBN(0), "ether"))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">ONE</InputAdornment>
                  ),
                }}
                fullWidth
                disabled={!(!isForSale && nftOwner === account)}
              />
            </Grid>
            <Grid item xs={4}>
              {nftOwner === account && !isForSale && !txFinished && !txPending && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => putForSale(parseInt(nftId), salePrice)}
                >
                  Sell
                </Button>
              )}
              {nftOwner === account && isForSale && !txFinished && !txPending && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => cancelListing(parseInt(saleId))}
                >
                  Cancel
                </Button>
              )}
              {nftOwner !== account && isForSale && !txFinished && !txPending && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => buy(saleId, price)}
                >
                  Buy
                </Button>
              )}
              {
                txPending ?
                  <CircularProgress /> :
                  null
              }
              {
                txFinished ?
                  <CheckOutlinedIcon /> :
                  null
              }
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Item;
