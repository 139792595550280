import { ActionTypes } from "../constants/action-types";

const intialState = {
  nft: [],
  account: '',
  artTokenContract: null,
  offspringContract: null,
  marketplaceContract: null,
  marketplaceAddress: "0x8feA785c3f693242A739422300300Ce82e4989ff",
  offspring: [],
};

export const nftReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_NFT:
      return { ...state, nft: payload };

    case ActionTypes.SET_ACCOUNT:
      return { ...state, account: payload };
    
    case ActionTypes.SET_TOKEN_CONTRACT:
      return { ...state, artTokenContract: payload }; 

    case ActionTypes.SET_OFFSPRING_CONTRACT:
      return { ...state, offspringContract: payload };
    
    case ActionTypes.SET_OFFSPRING:
      return { ...state, offspring: payload };

    case ActionTypes.SET_MARKET_CONTRACT:
      return { ...state, marketplaceContract: payload };
    default:
      return state;
  }
};

export const selectedNftReducer = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SELECTED_NFT:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_NFT:
      return {};
    default:
      return state;
  }
};

