import React from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";

import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";

import { useStyles } from "./styles.js";
import { ReactComponent as EthereumLogo } from "../../assets/harmony-one-logo.svg";

const Card = ({ tokenId, name, image, price, owner, isForSale, isParent, listingsOnly }) => {
  const classes = useStyles();
  var nftType;
  if (isParent) {
    nftType = "parent";
  } else {
    nftType = "offspring";
  }

  return (
    <Link to={{
      pathname: `/nft/${nftType}/${tokenId}`
    }}>
      <MuiCard className={classes.root}>
        <CardActionArea>
          <CardMedia
            component="div"
            dangerouslySetInnerHTML={{ __html: image }}
          />
          <CardContent className={classes.content}>
            <div className={classes.title}>
              <Typography
                className={"MuiTypography--heading"}
                variant={"h5"}
                gutterBottom
              >
                {name}
              </Typography>
              {isForSale && listingsOnly ?
                <Chip
                  size="small"
                  disabled={true}
                  label="Selling"
                  className={classes.badge}
                /> : 
                null}
            </div>
            {listingsOnly ?
              <Typography variant="h6" className={classes.price}>
                <SvgIcon
                  component={EthereumLogo}
                  viewBox="20 -20 200 246.6"
                  titleAccess="ETH"
                />
                <span>{Web3.utils.fromWei(String(price), "ether")}</span>
              </Typography> :
              null}
          </CardContent>
        </CardActionArea>
      </MuiCard>
    </Link>
  );
};

export default Card;
