import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Web3 from "web3";
import ScribblesABI from './abi/scribbles.json';
import Offspring from './abi/ScribblesOffspring.json';
import { useMediaQuery } from "@material-ui/core";
import { combine } from '../../svg/test.js';

import {FormControl} from '@mui/material';
import {InputLabel} from '@mui/material';
import {MenuItem} from '@mui/material';
import {FormHelperText} from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';

import { useStyles } from "./styles.js";

import DropZone from "../../components/DropZone";

import { api } from "../../services/api";

const CreateNFT = () => {
  const parentAddress = "0xDB18774dCa16F1c5C2F7Af640EBA3edb19343D7d";
  const offspringAddress = "0x1E03ca2D54682ecdE845e5385f5AB8E0E62343C9";
  const classes = useStyles();
  const history = useHistory();

  // const account = useSelector((state) => state.allNft.account);
  const [account, setAccount] = useState();
  const [NFTs, setNFTs] = useState();
  const [parentNFT, setParentNFT] = useState();

  // parent contract states
  const [scribbleSVG, setScribbleSVG] = useState();
  const [rentID, setRentID] = useState();
  const [scribblesContract, setScribblesContract] = useState();

  // offspring contract states
  const [offspringBalance, setOffspringBalance] = useState();
  const [offspringSupply, setOffspringSupply] = useState();
  const [mintTally, setMintTally] = useState();
  const [rentTally, setRentTally] = useState();
  const [rentedRentTally, setRentedRentTally] = useState();
  const [offspringContract, setOffspringContract] = useState();

  const sizeMD = useMediaQuery('(min-width:600px)');

  // UI states
  const [ID, setID] = useState();
  const [mintAmount, setMintAmount] = useState();

  const artTokenContract = useSelector(
    (state) => state.allNft.artTokenContract
  );

  const [selectedFile, setSelectedFile] = useState();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
  });

  useEffect(() => {
    loadWeb3();
    // initialise();
    window.ethereum.on('accountsChanged', () => {
      window.location.reload();
    });

    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    });

  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await window.web3.eth.getAccounts();
      setAccount(accounts[0]);
      getOwnedParents();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      const accounts = await window.web3.eth.getAccounts();
      setAccount(accounts[0]);
      getOwnedParents();
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
    console.log("load web3 completed");
  };

  const getOwnedParents = async () => {

    window.web3 = new Web3(window.web3.currentProvider)
    const accounts = await window.web3.eth.getAccounts()
    const web3 = window.web3;
    console.log("account: " + accounts[0]);
    var chainID = await web3.eth.net.getId();
    if (chainID == 1666600000) {

      const contract = new web3.eth.Contract(ScribblesABI, parentAddress);
      setScribblesContract(contract);
      const offContract = new window.web3.eth.Contract(Offspring.abi, offspringAddress);
      setOffspringContract(offContract);

      var data = []
      var count = await contract.methods.balanceOf(accounts[0]).call();
      console.log("count is : " + count);
      if (count > 0) {
        setNFTs([]);
        for (let i = 0; i < count; i++) {
          let NFTIndex = await contract.methods.tokenOfOwnerByIndex(accounts[0], i).call();
          let NFTSVG = await contract.methods.tokenURI(NFTIndex).call();
          console.log(NFTIndex + ":" + NFTSVG);
          data.push({ index: NFTIndex, svg: NFTSVG });
        }
        setNFTs(data);
      }
    } else {
      window.alert('you are not on harmony mainnet, please choose the right network');
    }

  }

  const selectParent = async (NFTData) => {
    setParentNFT(NFTData);

    let mintCounter = await offspringContract.methods.getMintCounter(NFTData.index).call();
    setMintTally(mintCounter);
    let rentCounter = await offspringContract.methods.getRentCounter(NFTData.index).call();
    setRentTally(rentCounter);
  }

  function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }

  const getRentScribbles = async (_parentIndex) => {
    const web3 = window.web3;
    // Load account
    const accounts = await web3.eth.getAccounts();
    setAccount(accounts[0]);
    setRentID(_parentIndex);
    var data = null
    console.log("parent index is : " + _parentIndex);

    if (_parentIndex) {
      console.log(scribblesContract);
      data = await scribblesContract.methods.tokenURI(_parentIndex).call();
    }
    setScribbleSVG(data);
    console.log(data);

    combine(data);

    const offspringContract = new window.web3.eth.Contract(Offspring.abi, offspringAddress);
    let rentCounter = await offspringContract.methods.getRentCounter(_parentIndex).call();
    setRentedRentTally(rentCounter);
  };

  const mintOffspring = async () => {
    if (parentNFT == null || rentID == null) {
      console.log("no rented and/or owned parent specified!");
    } else {
      console.log("mint initiated");
      const web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      const parentID = parentNFT.index;
      console.log(`parent ID : ${parentID} rent ID : ${rentID}`);
      let mintCost;
      if (isNaN(mintAmount)) {
        mintCost = 1;
      } else {
        mintCost = mintAmount * 1;
      }

      await offspringContract.methods.mint(isNaN(mintAmount) ? 1 : mintAmount, parentID, rentID).send({
        from: accounts[0],
        value: Web3.utils.toWei(String(mintCost), "ether")
      }).then(tx => {
        console.log(tx);
        console.log("minting successful");
      }).catch(err => {
        console.log(err);
        console.log("minting errored");
      });

    }
  };

  const handleSliderChange = (event, newValue) => {
    setMintAmount(newValue);
  };

  return (
    <Grid container xs={12} justifyContent="center" spacing={1}>
      {/*********************************************************/}
      {/******** start of select owned scribble section *********/}
      {/*********************************************************/}
      <Grid item display="flex" justifyContent="center" xs={sizeMD ? 5 : 12}>
        <Grid container justifyContent="center" direction="column" >
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <div className={classes.formHeader}>
                <h1>Select Owned Scribble</h1>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <div className={classes.indexes}>
                <FormControl >
                  <InputLabel id="demo-simple-select-label">ID</InputLabel>
                  <Select
                  sx={{minWidth: 100}}
                  labelId="demo-simple-select-helper-label"
                  value={parentNFT ? parentNFT.index : "0"}
                  id="demo-simple-select-helper"
                  label="ID">
                    {
                      NFTs ? NFTs.map(NFT => {
                        return (
                          <MenuItem onClick={() => selectParent(NFT)} value={NFT.index}>{NFT.index}</MenuItem>
                        )
                      }) : <MenuItem>None</MenuItem>
                    }
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MuiCard className={classes.svgZone} id="parent">
                {
                  parentNFT ?
                    <div dangerouslySetInnerHTML={{ __html: parentNFT.svg }} />
                    : <div></div>
                }
              </MuiCard>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignContent="center" direction="row">
              <Grid item xs={4}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs={12} >
                    <strong>Index</strong>
                  </Grid>
                  <Grid item xs={12} >
                    <strong>{parentNFT ? parentNFT.index : "N/A"}</strong>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs={12}>
                    <strong>Mint Tally</strong>
                  </Grid>
                  <Grid item xs={12} >
                    <strong>{parentNFT ? mintTally : "N/A"}</strong>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs={12}>
                    <strong>Rent Tally</strong>
                  </Grid>
                  <Grid item xs={12}>
                    <strong>{parentNFT ? rentTally : "N/A"}</strong>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/*********************************************************/}
      {/*********** end of select owned scribble section ********/}
      {/*********************************************************/}
      {/******** start of select scribble partner section *******/}
      {/*********************************************************/}
      <Grid item display="flex" justifyContent="center" xs={sizeMD ? 5 : 12}>
        <Grid container justifyContent="center" direction="column" >
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <div className={classes.formHeader}>
                <h1>Select Scribble Partner</h1>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.indexes}>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item xs={sizeMD ? 2 : 4}>
                  <TextField
                    label="Index"
                    name="title"
                    variant="outlined"
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setID(e.target.value)
                      }
                    }}
                    value={ID}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={sizeMD ? 2 : 4}>
                  <Button onClick={(event) => {
                    event.preventDefault(); 
                    if (ID >= 0 && ID <= 1023 && parentNFT !== undefined )
                    {
                      console.log("parentNFT : " + parentNFT );
                      getRentScribbles(ID);
                    }                   
                    }} variant="contained" color="primary" size="large" type="submit">
                    Retrieve
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MuiCard className={classes.svgZone} id='rented'>
                {
                  scribbleSVG ?
                    <div dangerouslySetInnerHTML={{ __html: scribbleSVG }} />
                    : <div></div>
                }
              </MuiCard>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignContent="center" direction="row">
              <Grid item xs={4}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs={12} >
                    <strong>Index</strong>
                  </Grid>
                  <Grid item xs={12} >
                    <strong>{rentID ? rentID : "N/A"}</strong>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs={12}>
                    <strong>Rent Tally</strong>
                  </Grid>
                  <Grid item xs={12}>
                    <strong>{rentedRentTally ? rentedRentTally : "N/A"}</strong>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/********************************************************/}
      {/***********end of select scribble partner section*******/}
      {/********************************************************/}
      <Grid container xs={10} justifyContent="center" spacing={1}>
        <Grid container justifyContent="center" direction="row" >
          <Grid item xs={12}>
            <Grid container justifyContent="center" >
              <div className={classes.formHeader}>
                <h1>Create Scribbles Offspring</h1>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.indexes}>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item xs={sizeMD ? 2 : 6}>
                  <TextField
                    label="Owned Parent"
                    name="title"
                    variant="outlined"
                    required
                    value={parentNFT ? parentNFT.index : "please select an owned parent"}
                    onChange={handleInputChange}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={sizeMD ? 2 : 6}>
                  <TextField
                    label="Rented Parent"
                    name="title"
                    variant="outlined"
                    required
                    value={rentID ? rentID : "please select a parent to rent"}
                    onChange={handleInputChange}
                    disabled
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MuiCard className={classes.svgZone} id="test">
              </MuiCard>
            </Grid>
          </Grid>

          {/*        
          <Grid item xs={12}>
            <div className={classes.mintZone}>
              <Grid container justifyContent="center" alignItems="center" direction="row" spacing={2}>
                <Grid item xs={sizeMD ? 1 : 3}>
                  <Grid container direction="column" alignItems="center">
                    <Slider
                      aria-label="Always visible"
                      defaultValue={1}
                      value={mintAmount}
                      valueLabelDisplay="on"
                      step={1}
                      marks
                      min={1}
                      max={3}
                      onChange={handleSliderChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={sizeMD ? 1 : 3}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Button variant="contained" color="primary" onClick={mintOffspring}>
                      Mint
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
           */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateNFT;
