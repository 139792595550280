import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { List } from "@material-ui/core";
import ListItem from '@mui/material/ListItem';
import { Divider } from "@material-ui/core";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Grid } from "@material-ui/core";
import styled from "styled-components";

import { useStyles } from "./styles.js";
import { useMediaQuery } from "@material-ui/core";

import logo from "../../assets/Logo.svg";
import offspring from "../../assets/scribbleOffspringsm.svg";
import { Drawer } from "@material-ui/core";

const LoyaltyButton = styled.button`
  min-width: 100px;
  padding: 6px 14px;
  border-radius: 6px;
  border: 2px solid white;
  background: #4051b5;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  margin-right: 15px;
`;

const Header = ({ onModalToggle, account }) => {
  const classes = useStyles();
  const [state, setState] = useState({ right: false });

  const sizeMD = useMediaQuery('(min-width:600px)');

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar className={classes.header}>
        <Toolbar>
          <Grid container alignItems="center"  >
            <Grid item xs={sizeMD ? 6 : 10} >
              <Grid container alignItems="center">
                <Link to="/">
                  <img src={logo} alt="Scribbles" className={classes.logo} />
                </Link>
                <Link to="/offspring">
                  <img
                    src={offspring}
                    alt="Scribbles Offset"
                    className={classes.logo}
                  />
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={sizeMD ? 6 : 2}>
              {sizeMD ?
                <Grid container alignItems="center" justifyContent="flex-end">
                  <div className={classes.account}>
                    <LoyaltyButton onClick={onModalToggle}>Loyalty</LoyaltyButton>
                    <AccountBalanceWalletIcon
                      titleAccess="Wallet Address"
                      className={classes.walletIcon}
                    />
                    <Typography variant="subtitle1">
                      {account ? account.slice(0, 7) : ""}...{account ? account.slice(-4) : ""}
                    </Typography>

                  </div>
                </Grid>
                :
                <Grid container alignItems="center" justifyContent="flex-end">
                  <AddBoxRoundedIcon onClick={toggleDrawer("right", true)} />
                </Grid>
              }

            </Grid>
          </Grid>

          <Drawer
            anchor='right'
            open={state['right']}
            onClose={toggleDrawer('right', false)}>

            <List>
              <ListItem>
                <AccountBalanceWalletIcon
                  titleAccess="Wallet Address"
                  className={classes.walletIcon}
                />
                <Typography variant="subtitle1">
                  {account ? account.slice(0, 7) : ""}...{account ? account.slice(-4) : ""}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton onClick={onModalToggle}>
                  <ListItemText primary="Loyalty" />
                </ListItemButton>
              </ListItem>
              <Divider />
            </List>
            {/* <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <AccountBalanceWalletIcon
                    titleAccess="Wallet Address"
                    className={classes.walletIcon}
                  />
                  <Typography variant="subtitle1">
                    {account ? account.slice(0, 7) : ""}...{account ? account.slice(-4) : ""}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  
                </Grid>
              </Grid>
            </Grid> */}

          </Drawer>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment >
  );
};

export default Header;
