import { SVG  } from '@svgdotjs/svg.js';



// Using a bundler? It will automatically pick up a
// tree-shakeable ES modules version of color-blend:


//TODO https://www.bypeople.com/svg-mix-blend-modes-css-tweenmax-js/
//TODO https://mui.com/material-ui/react-slider/

export function combine(svg) {
    
//    var newDraw = SVG("#parent").inn;
//    var newDraw = document.querySelector("#parent").innerHTML;


    
    var newDraw1 = document.getElementById('parent');
    var newDraw1SVG = newDraw1.getElementsByTagName('svg')[0];
    var draw1Content = Array.from(newDraw1SVG.childNodes);


    var newDraw2 = document.getElementById('rented');
    var newDraw2SVG = newDraw2.getElementsByTagName('svg')[0];
    var draw2Content = Array.from(newDraw2SVG.childNodes);
    
    var offspring = document.getElementById('test');
    while (offspring.firstChild) {
        offspring.removeChild(offspring.firstChild);
    }
    //var offspring = SVG('#test');

    // create a merged-div where we are going to merge the svgs
    var merged = document.createElement('div');
    merged.setAttribute('id', 'merged-div');
    
    // createElementNS for svg
    var svgNS = "http://www.w3.org/2000/svg";  
    var mergedSvg = document.createElementNS(svgNS, 'svg');
    mergedSvg.setAttribute('id', 'merged');
    // keep the viewBox of the chart
    mergedSvg.setAttribute('viewBox', "0 0 260 260");
    
    // adding the content of both svgs
    var newData = "";
    for (let j = 0; j < draw2Content.length; j++) {

        var rect =  SVG(draw2Content[j]);
        var fill = rect.attr('fill');
       // console.log( "fill " + fill);
        
        if ( fill !== 'white')
        {
             
            mergedSvg.appendChild(draw2Content[j].cloneNode());
        }
    }

    for (let i = 0; i < draw1Content.length; i++) {
        var rect =  SVG(draw1Content[i]);
        var fill = rect.attr('fill');
        //console.log( "fill " + fill);
        
        if ( fill !== 'white')
        {
            mergedSvg.appendChild(draw1Content[i].cloneNode());
        }
    }
    //var lastColor = new SVG.Color("#000000");
    
    console.log(" mergedSvg.length : " +  mergedSvg.childElementCount)
    for (let j = 0; j < mergedSvg.childElementCount; j++) {

        var rect =  SVG(mergedSvg.childNodes[j]);
        var fill = rect.attr('fill');
        console.log( "fill " + fill);
        //rect

//normal(pinkBackground, greenForeground)
        
        //const newColor = lastColor.to(fill).at(0.5).toHex();
          rect.animate({
            duration: 3000,
            delay: 500,
            when: 'now',
            swing: true,
            times: 1,
            wait: 100
          })
          .attr({opacity: .75  })
          .transform({
            rotate: ( mergedSvg.childElementCount%2 == 0 ?  270 : 180) * ((j%3) > 0 ? -(j%3)  : 1.5),
            translateX: ((j%2) > 0 ? -42 : 22),
            translateY: ((j%2) > 0 ? -69 : -42),
            scale: ((j%2) > 0 ? 1.142 : 0.93124 ),
          });
          //lastColor = fill;
    }
    
    merged.appendChild(mergedSvg);
    offspring.appendChild(merged);
//    offspring.querySelectorAll("rect").remove();

 //offspring.append(SVG(newData));
//console.log( "NewData : " + newData);
//offspring.innerHTML = newData;

        //var SVG1 = SVG().data = newDraw1SVG;
 //   console.log ( "SVG1 : " + SVG1 );


    //console.log ( "newDraw2SVG : " + SVG(newDraw2SVG) );
//    console.log ( "newDraw2SVG : " + newDraw2SVG );
    

//    console.log ( "offspringSVG : " + offspringSVG );

    //offspringSVG.clear();

    //var merged = document.createElement('div');
    //merged.setAttribute('id', 'merged-div');
    


    //offspringSVG.data = newData;
    /*for (let i = 0; i < draw2Content.length; i++) {
        merged.appendChild(draw2Content[i]);
    }*/
   // console.log ( "newData : " + newData );
    //console.log ( "merged : " + merged );
    //offspring.


//    offspring.svg(svg);
    //offspring.combine(newDrawSVG1);
    //newDrawSVG1.insertAfter(offspring);
    //newDrawSVG2.(offspring);
    
//     newDrawSVG1.each(function(i, children) {
//     if ( this.fill)
//     {
//     if ( this.fill.color !== 'white')
//     {
//         offspring.add(this);
//     }
//     else
//     {
//         //this.fill({ color: 'none' })
//     }}
// }, true);

// newDrawSVG2.each(function(i, children) {
//     if ( this.fill )
//     {
//     if ( this.fill.color !== 'white')
//     {
//         offspring.add(this);
//     }
//     else
//     {
//         //this.fill({ color: 'none' })
//     }}
// }, true);

    //if ( draw )
    //{
    //    draw = SVG().addTo('#test').size('260', '260');
    //}

//    draw.svg(svg);

    //

//    var rect = draw.add(svg);

    /*SVG().addTo('#test').size('260', '260');

    var draw = SVG('#test');

    var newDraw = SVG(svg);
    var drawParent = SVG('#parent').toSvg();*/
//    console.log("NewDraw :"+ newDrawSVG);
//    console.log("NewnewDraw :"+ newnewDraw);
    //var drawRented = SVG('#rented');
    //var path = newDraw.path[0];
    
    //newDraw.rect(100,100).animate().fill('#f03').move(100,100);
//    path.fill('none').stroke({width:1, color: '#ccc'}).move(10,10).scale(0.5)

    //newDraw.addTo("#test");
    //newDraw.addTo(draw);
    //console.log ("draw :" + draw);
    //if ( draw )
    //{
    //    draw.rect.combine(svg);
    //}
    //else{
    //}
//    draw.put("#test");
//     //var canvas = SVG('test').size('100%', '100%')
//     //var draw = SVG('#test').size('260', '260');
//     //        , rect = canvas.rect(260, 260)
//     //      , 
//     //     , length = path.length()
//     var rect = draw.rect('260', '260');
//     var path = SVG().path("M 340,178 104,478 580,490 Z");
//     var length = path.length();

//    path.fill('none').stroke({width:1, color: '#ccc'}).move(10,10).scale(0.5)
//    path.animate(3000).rotate(365).loop();
//    rect.animate(5000, '<>').during(function(pos, morph, eased){
//         var m = draw.path.matrixify()
//         var p = new SVG.Point(draw.path.pointAt(eased * length)).transform(m)
//         draw.rect.move(p.x, p.y)
//     }).loop(true, true)
     // draw.svg(drawParent);
}